import { Link, UIMatch, useLocation, useMatches } from '@remix-run/react';
import { VariantProps, cva } from 'class-variance-authority';
import React from 'react';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '~/components/ui/breadcrumb';
import { cn } from '~/utils/cn';

const linkVariants = cva('', {
  variants: {
    variant: {
      default: '',
      primary: 'text-primary hover:text-primary',
      secondary: 'text-secondary hover:text-secondary',
    },
    size: {
      default: '',
      sm: 'text-sm',
      lg: 'text-lg',
      xl: 'text-xl',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
  },
});

type BreadcrumbProps = VariantProps<typeof linkVariants>;

export function Breadcrumbs({ variant, size }: BreadcrumbProps) {
  const matches = useMatches() as UIMatch<
    unknown,
    { breadcrumb?: (match: UIMatch) => React.ReactNode }
  >[];
  const location = useLocation();

  return (
    <Breadcrumb>
      <BreadcrumbList>
        {matches
          // skip routes that don't have a breadcrumb
          .filter((match) => match.handle && match.handle.breadcrumb)
          // render breadcrumbs!
          .map((match) => {
            const crumb = match?.handle?.breadcrumb?.(match);
            const homePathname = `${match?.pathname}${match?.params.locale}`;
            const matchPathname = match.pathname.split('/').filter(Boolean).join('/');
            const locationPathname = location.pathname.split('/').filter(Boolean).join('/');
            const isCurrentPage =
              matchPathname === locationPathname || homePathname === locationPathname;

            return isCurrentPage ? (
              <BreadcrumbItem key={match.pathname} className={linkVariants({ size })}>
                <BreadcrumbPage>{crumb}</BreadcrumbPage>
              </BreadcrumbItem>
            ) : (
              <React.Fragment key={match.pathname}>
                <BreadcrumbItem className={linkVariants({ size })}>
                  <BreadcrumbLink
                    asChild
                    className={cn(
                      'cursor-pointer no-underline hover:underline',
                      linkVariants({ variant })
                    )}
                  >
                    <Link to={match.pathname}>{crumb}</Link>
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
              </React.Fragment>
            );
          })}
      </BreadcrumbList>
    </Breadcrumb>
  );
}
